import React from 'react';
import './AngelHome.css';

function AngelHomeSelectParagraph({ name, relation }) {  // Destructure the props to access 'name'

    return (
        <a href={`angelhome/${name.replaceAll(' ', '-').toLowerCase()}`}> {/* Use backticks for string interpolation */}
            <div className='angelhome-select-paragraph'>{name}  ({relation})</div>

        </a>
    );
}

export default AngelHomeSelectParagraph;
