import React, { useState, useEffect } from 'react';
import './MayaBirthday.css';
import Confetti from 'react-confetti';
import {Parallax, ParallaxProvider} from "react-scroll-parallax";


function MayaBirthday() {
    const [daysPassed, setDaysPassed] = useState(0);
    const [moonOrbits, setMoonOrbits] = useState(0);
    const [heartBeats, setHeartBeats] = useState(0);
    const [daysSinceMet, setDaysSinceMet] = useState(0);
    const [realTimeHeartBeats, setRealTimeHeartBeats] = useState(0);
    const [showConfetti, setShowConfetti] = useState(true);

    // Constants
    const birthDate = new Date('2005-10-24');
    const moonOrbitDays = 27.32;
    const avgHeartBeatsPerMinute = 72;
    const avgHeartBeatsPerSecond = avgHeartBeatsPerMinute / 60;
    const avgHeartBeatsPerDay = avgHeartBeatsPerMinute * 60 * 24;

    useEffect(() => {
        const today = new Date();

        // Calculate days passed since birth
        const diffTime = Math.abs(today - birthDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        // Calculate moon orbits
        const moonOrbitCount = (diffDays / moonOrbitDays).toFixed(2);

        // Calculate total heartbeats up until now (approximate)
        const totalHeartBeats = diffDays * avgHeartBeatsPerDay;

        // Set initial values for days passed, moon orbits, and heartbeats
        setDaysPassed(diffDays);
        setMoonOrbits(moonOrbitCount);
        setHeartBeats(totalHeartBeats.toLocaleString());

        // Calculate days since meeting
        const metDate = new Date('2024-05-26'); // Date when you met
        const daysMetDiff = Math.floor((today - metDate) / (1000 * 60 * 60 * 24));
        setDaysSinceMet(daysMetDiff);

        // Set initial heartbeats and start the real-time heartbeat counter
        setRealTimeHeartBeats(totalHeartBeats);

        const intervalId = setInterval(() => {
            setRealTimeHeartBeats((prevHeartBeats) => Math.round(prevHeartBeats + avgHeartBeatsPerSecond));
        }, 1000); // Update every second

        // Confetti should stop after 5 seconds
        const confettiTimeout = setTimeout(() => setShowConfetti(false), 5000);

        // Clean up intervals and confetti timeout
        return () => {
            clearInterval(intervalId);
            clearTimeout(confettiTimeout);
        };
    }, []); // Empty dependency array means this effect runs once after initial render

    return (
        <ParallaxProvider>
            <div id="maya-birthday-body">
                {showConfetti && <Confetti />}

                {/* Top Banner with Slow Parallax */}
                    <div id='maya-birthday-topbar'>
                        <img src="mayapicture/banner2.svg" alt="Birthday Banner" />
                    </div>

                {/* Main Header Section with Faster Parallax */}
                <Parallax speed={15}>
                    <div id="maya-header-divider">
                        <div id="happy-birthday-text">
                            Happy Birthday Maya
                        </div>
                        <br />
                        <img src="mayapicture/throwheart.gif" alt="Throw Heart Gif" />
                    </div>
                </Parallax>
                <Parallax speed={30} className="no-vertical-margin">
                    <div id="maya-birthday-stats">
                        <h1><span className="blue-font">{daysPassed}</span> days have passed since your birth <img src="mayapicture/sun.svg"  id="maya-sun-svg" className="maya-stats-svg"/></h1>
                        <h1>The moon has orbited the earth <span className="blue-font">{moonOrbits}</span> times since your birth  <img src="mayapicture/moon.svg"  id="maya-moon-svg" className="maya-stats-svg"/></h1>
                        <h1>Your heart has beaten around <span className="blue-font">{realTimeHeartBeats.toLocaleString()}</span> times in total <img src="mayapicture/heart.svg"  id="maya-heart-svg" className="maya-stats-svg"/></h1>
                        <h1>And it has been <span className="blue-font">{daysSinceMet}</span> days since you've met me &lt;3 <img src="mayapicture/smirk.svg" className="maya-stats-svg"/></h1>
                    </div>
                </Parallax>


                <Parallax speed={10} className="no-vertical-margin">

                    <div id="maya-birthday-pictures-divider">
                        <div>
                            <img src="mayapicture/selfie1.jpg" alt="Selfie" />
                        </div>
                    </div>
                </Parallax>
                <Parallax speed={2} className="no-vertical-margin">

                <img id="maya-party-picture" src="mayapicture/party.png"/>
                </Parallax>

            </div>


        </ParallaxProvider>
    );
};


export default MayaBirthday;
