import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';

function NavBar() {
    const navigate = useNavigate(); // Using useNavigate hook

    const goToHomePage = () => {
        navigate('/'); // Navigate to the root URL
    };
    const goToBenjaminPage = () => {
        navigate('/benjamin'); // Navigate to the root URL
    };
    const goToJesterPage = () => {
        navigate('/jester'); // Navigate to the root URL
    };

    return (
        <header>
            <div id="nav-bar">
                <div className="nav-element" onClick={goToHomePage}><h1>Home</h1></div>
                <div className="nav-element"  onClick={goToBenjaminPage}><h1>Benjamin</h1></div>
                <div className="nav-element" onClick={goToJesterPage}><h1 >Jester</h1></div>
                <div className="nav-element"><h1>About me</h1></div>
            </div>
        </header>
    );
}

export default NavBar;
