import React, { useEffect } from 'react';
import paper from 'paper';

const RantCanvas = ({ color = 'grey', intervalTime = 200 }) => {
    useEffect(() => {
        // Initialize Paper.js on the canvas
        paper.setup('rant-background');

        const setBackground = (color) => {
            const backgroundColor = new paper.Path.Rectangle({
                rectangle: new paper.Rectangle(0, 0, paper.view.size.width, paper.view.size.height),
                fillColor: color,
                insert: false
            });
            paper.project.activeLayer.addChild(backgroundColor);
        };

        const regenCanvas = (color) => {
            paper.project.clear();
            setBackground(color);

            let originX = 1000;
            let originY = 500;
            let length = 2000;
            let offsetX = originX;
            let offsetY = originY;
            let totalStrokes = 1600;

            for (let i = 0; i < totalStrokes; i++) {
                if (i === totalStrokes / 5) {
                    offsetX = originX * 0.5;
                    offsetY = originY * 0.5;
                }
                if (i === totalStrokes * 2 / 5) {
                    offsetX = originX * 1.5;
                    offsetY = originY * 0.5;
                }
                if (i === totalStrokes * 3 / 5) {
                    offsetX = originX * 1.5;
                    offsetY = originY * 1.5;
                }
                if (i === totalStrokes * 4 / 5) {
                    offsetX = originX * 0.5;
                    offsetY = originY * 1.5;
                }

                const randomAngle = (Math.floor(Math.random() * 20)) / 10 * Math.PI;
                const randomOppositeAngle = (Math.random() * (1.8 - 0.8) + 0.8) * Math.PI + randomAngle;
                const randomLength = Math.random() * (length * 0.8) + length * 0.2;

                const lineX1 = Math.cos(randomAngle) * randomLength + offsetX;
                const lineY1 = Math.sin(randomAngle) * randomLength + offsetY;

                const lineX2 = Math.cos(randomOppositeAngle) * randomLength + offsetX;
                const lineY2 = Math.sin(randomOppositeAngle) * randomLength + offsetY;

                const startPoint = new paper.Point(lineX1, lineY1);
                const endPoint = new paper.Point(lineX2, lineY2);
                const line = new paper.Path.Line(startPoint, endPoint);

                line.strokeColor = "black";
            };
        };

        setBackground(color);
        const interval = setInterval(() => regenCanvas(color), intervalTime);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [color, intervalTime]); // Depend on color and intervalTime

    return (
        <canvas resize="true" id="rant-background" style={{ width: '100%', height: '100%' }}></canvas>
    );
};

export default RantCanvas;
