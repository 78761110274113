import React, {useEffect, useState} from 'react';
import './jester.css';

import cat from '../img/cat.svg';

import NavBar from "../components/NavBar";
import Socialsbar from "../components/Socialsbar";
import jesterpfp from '../img/Jesterpfp.jpeg';

function App() {

    function id(id) {
        return document.getElementById(id)
    }
    useEffect(() => {


    }, []);

    return (
        <div id='jester-body'>
            <NavBar></NavBar>
            <div id="jester-header">
                <div>
                    <h1>Jester <span>Shum</span></h1>
                    <p>Description sdfnsdkfsdkjfs ukdfsdf kjsdbfkjsd hksjdf kjskjdhf sf</p>
                </div>

                <div>
                    <img src={jesterpfp}/>
                </div>
            </div>

            <div className={"row-flex"}>
                <div id="jester-education">
                    <div>
                        <h2>Education</h2>
                        <li>
                            <p>tesrte</p>
                            <p>tesrte</p>

                            <p>tesrte</p>

                            <p>tesrte</p>

                        </li>
                    </div>
                </div>
                <div id="jester-activities">
                    <h2>Activities</h2>
                    <li>
                        <p>tesrte</p>
                        <p>tesrte</p>

                        <p>tesrte</p>

                        <p>tesrte</p>
                    </li>
                </div>
            </div>
        </div>

    );
}

export default App;