import React from 'react';
import github_logo from '../img/github-logo.svg'
import linkedin_logo from '../img/linkedin-logo.svg'

import './SocialsBar.css';

function Socialsbar({ github = false, linkedin = false, email = false, cv = false }) {
    return (
        <div className="socials-bar">
                {github && <a href={github}><img className="github-logo" src={github_logo} alt="GitHub" /></a>}
                {linkedin && <a href={linkedin}><img className="github-logo" src={linkedin_logo} alt="Linkedin" /></a>}
                {email && <h1>Email</h1>}
                {cv && <h1>CV</h1>}

        </div>

    );
}

export default Socialsbar;
