import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import './rants.css';
import RantCanvas from '../components/RantCanvas';

const supabaseUrl = 'https://haqoqpbjmroxbdstvato.supabase.co';
const supabaseKey = '    ' +
    '\'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhhcW9xcGJqbXJveGJkc3R2YXRvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIxODc4NDEsImV4cCI6MjAyNzc2Mzg0MX0.ZkLNyYp6EzfawDvtKBHjQaCRZnA567Lvoonyf7j5V9A\';\n'
const supabase = createClient(supabaseUrl, supabaseKey);

const App = () => {
    const [rants, setRants] = useState([]);

    useEffect(() => {
        // fetchRants();
    }, []);

    async function fetchRants() {
        try {
            const { data, error } = await supabase.from('rants').select('*');
            if (error) {
                console.error('Error fetching data:', error.message);
            } else {
                setRants(data);
                console.log(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return (
        <div id="rant-body">
            <script src="https://accounts.google.com/gsi/client" async></script>


            <RantCanvas/>
            <div id="rant-content">
                <h1 id="heading">These are rants and frustrations I have</h1>
                <ul>
                    {rants.map((rant) => (
                        <li key={rant.id}>
                            <h3>{rant.title}</h3>
                            <p>{rant.content}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default App;
