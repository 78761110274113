import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";

const MakeMemoryPara = () => {
    const { name } = useParams();
    const [showName, setShowName] = useState('');

    const [paraContent, setParaContent] = useState([]);


    function makeParagraph(data) {
        console.log(name)
        setShowName(name.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()));
        if (data.startsWith('<!DOCTYPE html>')) {
            setParaContent("404 not found")
            return
        }

        const tempList = data.split('\n').map((line, index) => (
            <p key={index} style={{whiteSpace:'pre-wrap'}}>{line}<br/></p> // Ensure each <p> has a unique key (using index here)
        ));
        setParaContent(tempList);
    }

    useEffect(() => {
        fetch(`/memoryparagraph/${name}.txt`)
            .then(response => response.text())
            .then(data  => {
                makeParagraph(data)
            })
            .catch(error => console.error('Error fetching file:', error));

    }, []);

    return (
        <div style={{fontFamily:`'Noto Serif TC', serif`,
            fontOpticalSizing: 'auto',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: `1.5em`,
            backgroundColor: `#a6b4ff`,
            padding:`10px `,

        }}
        >
            <button onClick={() => window.location.href = "/angelhome"} className="angelhome-return-button">
                 返回首頁  </button>

            <h1>{showName}</h1>
            <div className='mom-waterside-container'>
                <img className="mom-waterside" src={`/memoryparagraph/${name}.jpeg`}/>
            </div>
            <div>{paraContent}</div>
        </div>
    );
}

export default MakeMemoryPara;